import React, { useState, useEffect } from "react";
import {
  CardContainer,
  CardHeading,
  CardTitle,
  CustomDropdownItemV1,
  CustomMenuV1,
  CustomToggleV1,
  FilterIconConatainer,
  LeftSection,
} from "./styles";
import RecognitionCommonCard from "./RecognitionCommonCard";
import {
  ExploringYourImpactIcon,
  FilterIcon,
  TeamAnalyticsIcon,
} from "../../utils/icons";
import {
  GetMostPraisedMembersDetails,
  GetFrequentRecognitionGiverDetails,
  GetLeadingSupporterDetails,
  GetTopAcknowledgmentsDetails,
} from "../../redux/constants/apiConstants";
import { fetchApi, getStartAndEndDates } from "../../utils/methods";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";

// const CustomToggle = styled.div`
//   &::after {
//     display: none !important;
//   }
// `;

function RecognitionCardContainer() {
  const [praisedMembersData, setPraisedMembersData] = useState([]);
  const [recognitionGiverData, setRecognitionGiverData] = useState([]);
  const [leadingSupportedData, setLeadingSupportedData] = useState([]);
  const [topAcknowledgmentsData, setTopAcknowledgmentsData] = useState([]);
  const [timeInterval, setTimeInterval] = useState("Monthly");
  const [exploringTimeInterval, setExploringTimeInterval] = useState("Monthly");

  useEffect(() => {
    fetchMostPraisedMembersData();
    fetchFrequentRecognitionGiverData();
    fetchLeadingSupporterData();
    fetchTopAcknowledgmentsData();
  }, [timeInterval]);

  useEffect(() => {
    fetchLeadingSupporterData();
    fetchTopAcknowledgmentsData();
  }, [exploringTimeInterval]);

  const handleSelect = (eventKey) => {
    setTimeInterval(eventKey);
  };

  const exploreHandleSelect = (eventKey) => {
    setExploringTimeInterval(eventKey);
  };

  const fetchMostPraisedMembersData = async () => {
    const { startDate, endDate } = getStartAndEndDates(timeInterval);
    const apiUrl = `${GetMostPraisedMembersDetails}/${startDate}/${endDate}`;

    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {
        setPraisedMembersData(res.data);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  const fetchFrequentRecognitionGiverData = async () => {
    const { startDate, endDate } = getStartAndEndDates(timeInterval);
    const apiUrl = `${GetFrequentRecognitionGiverDetails}/${startDate}/${endDate}`;

    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {
        setRecognitionGiverData(res.data);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  const fetchLeadingSupporterData = async () => {
    const { startDate, endDate } = getStartAndEndDates(exploringTimeInterval);
    const apiUrl = `${GetLeadingSupporterDetails}/${startDate}/${endDate}`;

    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {
        setLeadingSupportedData(res.data);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  const fetchTopAcknowledgmentsData = async () => {
    const { startDate, endDate } = getStartAndEndDates(exploringTimeInterval);
    const apiUrl = `${GetTopAcknowledgmentsDetails}/${startDate}/${endDate}`;
    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {
        setTopAcknowledgmentsData(res.data);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  return (
    <>
      <CardContainer isMargin={"20px"}>
        <CardHeading>
          <LeftSection>
            <div>{TeamAnalyticsIcon()}</div>
            <CardTitle>Team Analytics</CardTitle>
          </LeftSection>
          <FilterIconConatainer>
            <Dropdown onSelect={handleSelect} style={{ cursor: "pointer" }}>
              <CustomToggleV1 id="dropdown-custom-components">
                {FilterIcon()}
              </CustomToggleV1>
              <CustomMenuV1 align="end">
                {["Monthly", "Quarterly", "Yearly"].map((interval) => (
                  <CustomDropdownItemV1
                    key={interval}
                    eventKey={interval}
                    active={timeInterval === interval}
                  >
                    {interval}
                  </CustomDropdownItemV1>
                ))}
              </CustomMenuV1>
            </Dropdown>
          </FilterIconConatainer>
        </CardHeading>
        <div style={{ display: "flex", rowDirection: "column", gap: "20px" }}>
          <RecognitionCommonCard
            title={"Most Praised Team Members"}
            data={praisedMembersData}
            lastColumnName={"Recognition Received"}
          ></RecognitionCommonCard>
          <RecognitionCommonCard
            title={"Frequent Recognition Giver"}
            data={recognitionGiverData}
            lastColumnName={"Recognition Given"}
          ></RecognitionCommonCard>
        </div>
      </CardContainer>

      <CardContainer isMargin={"0px"} style={{ marginBottom: "95px" }}>
        <CardHeading>
          <LeftSection>
            <div>{ExploringYourImpactIcon()}</div>
            <CardTitle>Exploring Your Impact</CardTitle>
          </LeftSection>
          <FilterIconConatainer>
            <Dropdown
              onSelect={exploreHandleSelect}
              style={{ cursor: "pointer" }}
            >
              <CustomToggleV1 id="dropdown-custom-components">
                {FilterIcon()}
              </CustomToggleV1>
              <CustomMenuV1 align="end">
                {["Monthly", "Quarterly", "Yearly"].map((interval) => (
                  <CustomDropdownItemV1
                    key={interval}
                    eventKey={interval}
                    active={timeInterval === interval}
                  >
                    {interval}
                  </CustomDropdownItemV1>
                ))}
              </CustomMenuV1>
            </Dropdown>
          </FilterIconConatainer>
        </CardHeading>
        <div style={{ display: "flex", rowDirection: "column", gap: "20px" }}>
          <RecognitionCommonCard
            title={"Your Leading Supporter"}
            data={leadingSupportedData}
            lastColumnName={"Recognition Received"}
          ></RecognitionCommonCard>
          <RecognitionCommonCard
            title={"Your Top Acknowledgments "}
            data={topAcknowledgmentsData}
            lastColumnName={"Recognition Given"}
          ></RecognitionCommonCard>
        </div>
      </CardContainer>
    </>
  );
}

export default RecognitionCardContainer;
